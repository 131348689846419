import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { State } from '@/store/models';

@Component({
  components: {
    WithDownloads,
  },
})

export default class LandingAccessible extends Vue {
  @StateClass assets!: State['assets'];

  get latestAssets(): State['assets'] {
    return this.assets && this.assets.length ? this.assets.slice(0, 2) : [];
  }
}
