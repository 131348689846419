import Vue from 'vue';
import Component from 'vue-class-component';
import { State as StateClass, Action } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { State } from '@/store/models';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import LogoAlt from '@/modules/landing/components/logo-alt/LogoAlt.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import { Asset } from '@/store/models/asset';
import { ValidationObserver } from 'vee-validate';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import { Watch } from 'vue-property-decorator';
import * as MUTATIONS from '@/store/constants';

@Component({
  components: {
    BannerLegal,
    LogoAlt,
    Navbar,
    FormInput,
    ValidationObserver,
  },
})

export default class LandingHeader extends Vue {
  isFixed = false;
  @StateClass assets!: State['assets'];
  @StateClass operations!: State['operations'];
  @Action sendEmail!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  $: (e: string) => any = (): null => null;
  check: () => void = (): void => {};
  tag: string | null = null;
  threshold: number = 1000;
  email: string = '';

  mounted(): void {
    this.$ = (e: string): any => document.querySelector(e);
    this.tag = this.getTargetTag();
    this.main();
  }

  @Watch('operations')
  onSupportChanged(newOperation: any, oldOperation: any): void {
    if (newOperation?.name === MUTATIONS.SEND_EMAIL_ERROR) {
      this.addToastMessage({
        text: newOperation.error,
        type: 'danger',
      });
    }

    if (newOperation?.name === MUTATIONS.SEND_EMAIL_SUCCESS) {
      this.addToastMessage({
        text: this.$t('landing.header.stayInTouchSubmitSuccess'),
        type: 'success',
      });
    }
  }

  getTargetTag(): string {
    if (navigator.userAgent.includes('Edge')) {
      return 'body';
    }
    if (navigator.userAgent.includes('Firefox/') && ((+navigator.userAgent.split('Firefox/')[1]) >= 62)) {
      return 'html';
    }
    if (!(window as any).chrome
      && 'WebkitAppearance' in (document.documentElement as any).style) {
      return 'body';
    }
    return 'html';
  }

  registerEvent(): void {
    window.addEventListener('scroll', this.check);
  }

  removeEvent(): void {
    window.removeEventListener('scroll', this.check);
  }

  main(): void{
    this.check = (): void => {
      const { $, tag, threshold } = this;
      if (!tag) {
        return;
      }
      this.isFixed = $(tag).scrollTop > threshold;
    };
    this.registerEvent();
  }

  beforeDestroy(): void {
    this.removeEvent();
  }

  submitSubscription(): void {
    this.sendEmail({
      from: this.email,
      subject: this.$t('landing.header.stayInTouchSubmitSubject'),
      body: this.$t('landing.header.stayInTouchSubmitBody', { email: this.email }),
    });
  }

  get asset(): Asset {
    return this.assets[0];
  }
}
