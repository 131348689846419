import Vue from 'vue';
import Component from 'vue-class-component';
import BackToTop from '@/components/common/back-to-top/BackToTop.component.vue';
import LandingHeader from '@/modules/landing/components/header/LandingHeader.vue';
import LandingAccessible from '@/modules/landing/components/accessible/LandingAccessible.vue';
import LandingSteps from '@/modules/landing/components/steps/LandingSteps.vue';
import LandingSafeguards from '@/modules/landing/components/safeguards/LandingSafeguards.vue';
import LandingRisks from '@/modules/landing/components/risks/LandingRisks.vue';
import LandingFooter from '@/modules/landing/components/footer/LandingFooter.vue';
import Support from '@/components/common/support/Support.vue';
import { intercom } from '../../../../whitelabel.config';

@Component({
  components: {
    BackToTop,
    LandingHeader,
    LandingAccessible,
    LandingSteps,
    LandingSafeguards,
    LandingRisks,
    LandingFooter,
    Support,
  },
})

export default class Landing extends Vue {
  intercom = intercom;
}
